@tailwind base;
@tailwind components;
@tailwind utilities;

body.hide-chat-widget #brevo-conversations {
  display: none !important;
}

@layer base {
  html {
    font-family: "Inter", sans-serif;
    scroll-behavior: smooth;
  }
  body {
    scroll-behavior: smooth;
  }
}

@layer components {
  #scroll-nav-container {
    @apply scroll-smooth;
  }

  .input-base {
    @apply relative block w-full appearance-none border border-slate-300 px-3 py-2 text-slate-900 placeholder-slate-500 focus:z-10  sm:text-sm;
  }

  .prose-guide-text {
    @apply prose prose-slate max-w-3xl text-justify leading-6 dark:prose-invert prose-h1:text-2xl prose-h2:text-xl prose-h4:text-lg prose-h5:font-semibold prose-h5:text-slate-800 prose-table:table-fixed prose-hr:my-3 prose-h5:dark:text-slate-100;
    hyphens: auto;
  }

  .prose-guide-text .level-7 {
    @apply mt-8 font-semibold;
  }

  .prose-guide-text td {
    @apply mx-2 rounded-lg border-2 border-slate-50 bg-slate-100 px-2 dark:border-gray-900 dark:bg-slate-800;
  }

  .prose-guide-text h1 {
    @apply mt-5 border-b pb-2 font-semibold dark:border-slate-700 sm:mt-16;
  }

  .prose-guide-text h1:first-of-type {
    @apply mt-10;
  }

  .prose-guide-text .legal-ordinals-sample ul {
    @apply list-none pl-2;
  }

  .prose-answer-text {
    @apply prose prose-sm sm:prose-base prose-slate leading-6 dark:prose-invert prose-hr:my-3 max-w-none;
  }

  .prose-answer-text > :first-child {
    @apply mt-0;
  }

  .prose-answer-text > :last-child {
    @apply mb-0;
  }

  .prose-answer-text ul {
    @apply pl-6;
  }

  .prose-answer-text li.li-default {
    @apply -ml-6;
  }

  .prose-answer-text li.li-default > ul {
    @apply ml-6;
  }

  .prose-answer-text u {
    @apply underline decoration-primary-lighter decoration-2 underline-offset-2;
  }

  .keyboard-button {
    @apply rounded-md border border-slate-300 bg-slate-100 px-2 py-0.5 text-xs text-slate-700 dark:border-slate-500 dark:bg-slate-600 dark:text-slate-200;
  }

  .underline-strong strong {
    @apply underline decoration-primary-lighter decoration-2 underline-offset-2;
  }

  /* Change the format of alpha list items */
  .ordered-list.list-alpha > li.li-alpha {
    list-style-type: lower-alpha !important;
  }

  /* Add the closing parenthesis */
  .ordered-list.list-alpha > li.li-alpha::marker {
    content: counter(list-item, lower-alpha) ") ";
  }

  .prose .highlighted-tag,
  .prose-answer-text .highlighted-tag {
    @apply cursor-default rounded border-2 border-lime-400 bg-lime-50 px-[1px] py-[1px] text-lime-800 transition-all dark:border-lime-600 dark:bg-lime-900/30 dark:text-lime-300;
  }

  .prose .matched-tag,
  .prose-answer-text .matched-tag {
    @apply cursor-pointer rounded border border-slate-400 px-[1px] py-[1px] text-slate-900 transition-all hover:border-lime-500 hover:bg-lime-50 hover:text-lime-600 dark:border-slate-500 dark:text-slate-100 dark:hover:border-lime-700 dark:hover:bg-lime-800 dark:hover:text-lime-200;
  }
  
  /* Styling for partial matches that don't cover complete words */
  .prose .matched-tag.partial-match,
  .prose-answer-text .matched-tag.partial-match {
    @apply rounded-r-none border-r-0;
    position: relative;
    padding-right: 0;
  }
  
  /* Add the dashed border on the right side */
  .prose .matched-tag.partial-match::after,
  .prose-answer-text .matched-tag.partial-match::after {
    content: '';
    display: inline-block;
    margin-left: -1px;
    border-right: 1px dashed;
    border-color: inherit;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
  
  /* Special hover state for partial matches */
  .prose .matched-tag.partial-match:hover::after,
  .prose-answer-text .matched-tag.partial-match:hover::after {
    @apply border-lime-500 dark:border-lime-700;
  }

  .prose .matched-tag.partial-match::before,
  .prose-answer-text .matched-tag.partial-match::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 6px;
    background: linear-gradient(to right, transparent, rgba(226, 232, 240, 0.5));
    pointer-events: none;
    z-index: 1;
  }
  
  .dark .prose .matched-tag.partial-match::before,
  .dark .prose-answer-text .matched-tag.partial-match::before {
    background: linear-gradient(to right, transparent, rgba(30, 41, 59, 0.5));
  }
}

@layer utilities {
  .text-premium {
    @apply bg-gradient-to-br from-orange-500 to-red-400 bg-clip-text text-transparent;
    /* @apply bg-slate-800 dark:bg-slate-50 uppercase text-transparent bg-clip-text; */
  }

  .highlight-gradient-bg {
    @apply bg-gradient-to-br from-orange-600 to-pink-600;
  }

  .highlight-gradient-text {
    @apply bg-gradient-to-br from-orange-600 to-pink-500 bg-clip-text text-transparent;
  }

  .dashboard-box-background {
    @apply bg-white dark:bg-gray-900/30;
  }

  .dashboard-box-border {
    @apply border border-gray-200/50 dark:border-gray-700/20;
  }

  .btn-outline {
    @apply focus:outline-none focus:ring-2 focus:ring-primary-default focus:ring-offset-2 dark:focus:ring-offset-slate-900;
  }

  .focus-ring {
    @apply focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-slate-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2 dark:focus:ring-offset-slate-900 dark:focus-visible:ring-orange-600 dark:focus-visible:ring-offset-2 dark:focus-visible:ring-offset-slate-800;
  }

  /* e.g. buttons in navigation and menus */
  .btn-text-default {
    @apply focus-ring text-slate-800 hover:bg-gradient-to-br hover:from-slate-50 hover:to-slate-100 hover:text-slate-800 active:bg-slate-200 dark:text-gray-300 dark:hover:bg-gradient-to-br dark:hover:from-gray-900 dark:hover:to-gray-800 dark:hover:text-white dark:active:bg-gray-800;
  }

  .btn-text-default-active {
    @apply focus-ring bg-slate-200 bg-gradient-to-br from-slate-50 to-slate-100 text-orange-600 dark:bg-gradient-to-br dark:from-gray-900 dark:to-gray-800 dark:text-orange-500;
  }

  .btn-text-highlight-active {
    @apply border border-orange-500/60 bg-orange-500/5 text-orange-500 dark:bg-orange-500/5;
  }

  .btn-text-default-disabled {
    @apply pointer-events-none opacity-50;
  }

  .brand-gradient {
    @apply bg-gradient-to-r from-primary-default via-orange-500 to-secondary-default;
  }

  .preserve-3d {
    transform-style: preserve-3d;
  }
  .perspective {
    perspective: 5000px;
  }
  .backface-hidden {
    backface-visibility: "hidden";
  }

  .two-line-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .search-result-display mark {
    @apply rounded-sm bg-yellow-300 text-orange-600;
  }
}

/* Set up the counters inside the .legal-ordinals container */
.legal-ordinals {
  counter-set: level1 level2 level3 level4 level5 level6 level7 level8 level9;
}

/* Increment the relevant counter for each heading */
/* Only apply to h1 in .legal-ordinals */
.legal-ordinals .level-1::before {
  content: counter(level1, upper-roman) ". ";
  counter-increment: level1;
}

.legal-ordinals .level-1,
.legal-ordinals .level-1-reset {
  counter-reset: level2 level3 level4 level5 level6 level7 level8 level9;
}

.legal-ordinals .level-2::before {
  content: counter(level2, decimal) ". ";
  counter-increment: level2;
}

.legal-ordinals .level-2,
.legal-ordinals .level-2-reset {
  counter-reset: level3 level4 level5 level6 level7 level8 level9;
}

.legal-ordinals .level-3::before {
  content: counter(level3, lower-alpha) ") ";
  counter-increment: level3;
}

.legal-ordinals .level-3,
.legal-ordinals .level-3-reset {
  counter-reset: level4 level5 level6 level7 level8 level9;
}

.legal-ordinals .level-4::before {
  content: counter(level4, lower-alpha) counter(level4, lower-alpha) ") ";
  counter-increment: level4;
}

.legal-ordinals .level-4,
.legal-ordinals .level-4-reset {
  counter-reset: level5 level6 level7 level8 level9;
}

.legal-ordinals .level-5::before {
  content: "(" counter(level5, decimal) ") ";
  counter-increment: level5;
}

.legal-ordinals .level-5,
.legal-ordinals .level-5-reset {
  counter-reset: level6 level7 level8 level9;
}

.legal-ordinals .level-6::before {
  content: "(" counter(level6, lower-alpha) ") ";
  counter-increment: level6;
}

.legal-ordinals .level-6,
.legal-ordinals .level-6-reset {
  counter-reset: level7 level8 level9;
}

.legal-ordinals .level-7::before {
  content: "(" counter(level7, lower-alpha) counter(level7, lower-alpha) ") ";
  counter-increment: level7;
}

.legal-ordinals .level-7,
.legal-ordinals .level-7-reset {
  counter-reset: level8 level9;
}

.legal-ordinals .level-8::before {
  content: counter(level8, lower-greek) ") ";
  counter-increment: level8;
}

.legal-ordinals .level-8,
.legal-ordinals .level-8-reset {
  counter-reset: level9;
}

.legal-ordinals .level-9::before {
  content: counter(level9, lower-greek) counter(level9, lower-greek) ") ";
  counter-increment: level9;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate-blink {
  animation: blink 1s infinite;
}
